import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/about/Content";
import Team from "../sections/about/Team";
import CTA from "../sections/about/CTA";

const About = () => {
  return (
    <>
      <PageWrapper>
        <Hero title="About us">
          <p>Hotsnail is based on the beautiful Gold Coast, Australia. Hotsnail was founded by two software developers and all software is built in house. Hotsnails custom software provide efficient mail based solutions which allow us to keep our fees low while maintaining superior customer service. </p>
          <p>Hotsnail custom software suite provides all mail &amp; scanning solutions including - but not limited to - mail scanning, mail forwarding, mail/parcel warehousing and drop shipping</p>          
        </Hero>
        <Content />
      </PageWrapper>
    </>
  );
};
export default About;
