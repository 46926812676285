import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgContent1 from "../../assets/image/jpeg/easy-image-2-1.jpg";
import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="6">
            <Title variant="hero">
            Parcel forwarding since 2009
            </Title>
          </Col>
          <Col lg="6" className="pl-lg-5">
            <Text>
            Hotsnail has been electrifying snail mail, redirecting mail and forwarding parcels since 2009. HotSnail is a family run business based on the sunny Gold Coast. Our software design is inspired by loyal customers and hand crafted in-house for your service. We partner with Australia Post, DHL and TNT to provide you with world class delivery options worldwide.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;
